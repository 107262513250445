import { module } from 'modujs';
import LocomotiveScroll from 'locomotive-scroll';

export default class extends module {
    constructor(m) {
        super(m);
    }

    init() {
        this.scroll = new LocomotiveScroll({
            el: this.el,
            smooth: true,
            reloadOnContextChange: true,
            tablet: {
              smooth: true,
              multiplier: 10,
            },
            smartphone: {
              smooth: true,
              multiplier: 10,
            }
        });

        if($(document).outerWidth() < 501) {
          $('.b-relation-pages_wrap .c-card').attr('data-scroll-speed', 0);
        }

        setTimeout(() => {
          this.scroll.update();
        }, 500);
        new ResizeObserver(() => this.scroll.update()).observe(this.el);

        this.scroll.on('call', (func, way, obj, id) => {
            // Using modularJS
            this.call(func[0], { way, obj }, func[1], func[2]);
            
            if (way === 'enter') {
              switch (func) {
                case "pageColor":
                  // get color code from data-scroll-id  assigned to body by obj.id
                  document.querySelector('body').style.backgroundColor = obj.el.getAttribute('data-color');
                  break;      
             }
            }
        });

        this.scroll.on('scroll', (args) => {
            // console.log(args.scroll);
        })

        if($(document).outerWidth() < 900) {
          $('#navScroll').attr('data-scroll-target', '');
        }
    }

    toggleLazy(args) {
        let src = this.getData('lazy', args.obj.el)
        if (src.length) {
            if (args.obj.el.tagName === 'IMG') {
                args.obj.el.src = src
            } else {
                args.obj.el.style.backgroundImage = `url('${src}')`
            }
            this.setData('lazy', '', args.obj.el)
        }
    }

    destroy() {
        this.scroll.destroy();
    }

    scrollTo(target) {
      this.scroll.scrollTo(target);
    }

    update() {
      this.scroll.update();
    }

}
